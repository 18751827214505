<header id="page-topbar" class="bg-primary">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logg.png" alt="" height="22">
          </span>
        </a>
      </div>


      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>
      <div class="dropdown d-inline-block d-lg-none me-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" ngbDropdownToggle>
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-10.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{ nonComplet }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>

            <a class="dropdown-item"  href="javascript: void(0);" (click)="profil()">
              <i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profil</span></a>


<!--               <a class="dropdown-item"  href="javascript: void(0);" (click)="parametre()">
                <i class="bx bx-cog font-size-16 align-middle me-1"></i> <span key="t-parametre">Paramètre</span></a> -->
            <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
            class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Déconnexion</a>

        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="caisse()">
          <i class='bx bxs-archive-in'></i>
        <span class="d-none d-xl-inline-block ms-2" style="color: white;">Caisse</span>
        </button>
      </div>
<!--      <div class="dropdown d-inline-block">-->
<!--        <button type="button" class="btn header-item noti-icon right-bar-toggle">-->
<!--          <i class="bx bx-cog bx-spin"></i>-->
<!--          <span class="d-none d-xl-inline-block ms-2" style="color: white;">Paramètrage</span>-->
<!--        </button>-->
<!--      </div>-->
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>
              <ng-container *ngIf="item.subItems">
                <a class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                  href="javascript: void(0);" id="topnav-components" role="button">
                  <i class="bx {{item.icon}} me-1"></i>
                  {{ item.label | translate }}
                  <div class="arrow-down"></div>
                </a>
              </ng-container>
              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2': item.subItems.length > 11}"
                aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)"
                    class="col dropdown-item side-nav-link-ref" [routerLink]="subitem.link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    {{ subitem.label | translate }}
                  </a>
                  <div *ngIf="item.subItems.length > 11">
                    <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="subitem.link">{{ item.subItems[i].label | translate }}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 1]?.link">{{ item.subItems[i + 1]?.label | translate }}</a>
                      </div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 2]?.link">{{ item.subItems[i + 2]?.label | translate }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);" [routerLink]="subSubitem.link"
                          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          {{ subSubitem.label | translate }}
                        </a>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
