<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary"> Bienvenue Taiba Cours Privé </h5>
                  <p><< Être et non paraitre >>.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <a routerLink="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                  </span>
                </div>
              </a>
            </div>
            <div class="p-2">
              <form class="form-horizontal" [formGroup]="f"  (ngSubmit)="onLogin()" >
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">Identifiant ou mot de passe incorect!</ngb-alert>
                <!-- <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert> -->

                <div class="mb-3">
                  <i class="fas fa-user"></i>
                  <label for="login">&nbsp;Login</label>
                  <input type="text" formControlName="login" class="form-control"  placeholder="Entrer le Login" autocomplete="user"
                  />
                </div>

                <div class="mb-3">
                  <i class="fas fa-lock"></i>
                  <label for="password">&nbsp;Mot de passe</label>
                  <div class="input-group auth-pass-inputgroup">

                    <input type="password" formControlName="password" class="form-control"  autocomplete="password" placeholder="Entrer le mot de passe" autocomplete="current-password" />
                    <!-- <button class="btn btn-light ms-0" type="button" id="password-addon"><i
                        class="mdi mdi-eye-outline"></i></button> -->

                  </div>
                </div>

                <div class="mt-3 d-grid">
                  <button class="btn btn-primary" type="submit"  value="Login" (click)="onLogin()" >Se connecter</button>
                </div>

<!--                <div *ngIf="error" class="error-message">{{ error }}</div>-->


                <div class="mt-4 text-center">
                  <a  class="text-muted" value="Login"><i class="mdi mdi-lock mr-1"></i> Mot de passe oublié ?</a>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div class="mt-5 text-center">
<!--           <p>Don't have an account ? <a routerLink="/account/signup" class="fw-medium text-primary"> Signup
              now
            </a> </p>  -->

        </div>

      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->
